import * as React from 'react';
import { isMoreThanXMonthsOld, isoDateToCommonFormat } from '../../shared/helpers/dateHelper';
import styles from './ChildMentalEmotionalCheckInFace.module.scss';
import DefaultTooltip from '../../shared/components/DefaultTooltip/DefaultTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const NUMBER_OF_MONTHS_OLD_CHECK_IN = 3;

type ChildMentalEmotionalCheckInFaceProps = {
  hasRisk?: boolean | null;
  date?: string | null;
};
export const ChildMentalEmotionalCheckInFace = (props: ChildMentalEmotionalCheckInFaceProps) => {
  const { t } = useTranslation();
  let hasRisk = props.hasRisk;

  if (!props.date) {
    return <div className={styles.bad}>{t('child.mental.LAST_EMOTIONAL_CHECK_IN_UNKNOWN')}</div>;
  }

  let dateColoringClass = '';
  if (isMoreThanXMonthsOld(props.date, NUMBER_OF_MONTHS_OLD_CHECK_IN)) {
    dateColoringClass = styles.bad;
  }
  return (
    <span>
      {hasRisk && (
        <DefaultTooltip id={'last-mental-check-in-has-risk'} tooltipText={t('emotionalCheckIn.HAS_RISK')}>
          <span className={'text-danger'}>
            <FontAwesomeIcon icon="face-frown" /> Last Check-in has risk{' '}
          </span>
        </DefaultTooltip>
      )}
      {!hasRisk && (
        <DefaultTooltip id={'last-mental-check-in-normal-risk'} tooltipText={t('emotionalCheckIn.NORMAL_RISK')}>
          <span>
            <FontAwesomeIcon icon="face-meh" /> Last Check-in - normal{' '}
          </span>
        </DefaultTooltip>
      )}
      <span className={styles.asOfDate}>
        {t('common.AS_OF')} <span className={dateColoringClass}>{isoDateToCommonFormat(props.date)}</span>
      </span>
    </span>
  );
};

export default ChildMentalEmotionalCheckInFace;
export type { ChildMentalEmotionalCheckInFaceProps };
